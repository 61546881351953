import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'settings',
        component: () => import('../views/UserSettings.vue'),
        children: [
          {
            path: 'cards-list',
            component: () => import('../views/UserSettings/CardsList.vue'),
          },
        ],
      },
      {
        path: 'itemposts/:dishUid',
        component: () => import('../views/PostsView.vue'),
        props: (route) => ({ dishUid: route.params.dishUid }),
      },
      {
        path: 'userposts/:userUid',
        component: () => import('../views/PostsView.vue'),
        props: (route) => ({ userUid: route.params.userUid }),
      },
      {
        path: 'post/:postUid/likers',
        component: () => import('../views/LikersView.vue'),
        props: (route) => ({ postUid: route.params.postUid }),
      },
      {
        path: 'user/:userUid',
        component: () => import('../views/UserView.vue'),
        props: (route) => ({ userUid: route.params.userUid }),
      },
      {
        path: 'orders/:orderUid',
        component: () => import('../views/OrderView.vue'),
        props: (route) => ({ orderUid: route.params.orderUid }),
      },
      {
        path: 'places/:placeUid',
        props: (route) => ({
          placeUid: route.params.placeUid,
          deepLink: true,
          menuModeQuery: Boolean(route.query.menumode),
          menuLangQuery: route.query.menulang,
        }),
        component: () => import('../views/ItemView.vue'),
      },
    ],
    beforeEnter: (_to, _from, next) => {
      /*
        Для временного переопределение глобальных стилей.
        ToDo: убрать после отказа от "position: fixed"
      */
      document.documentElement.classList.remove('__deep-link__')
      next()
    },
  },
  {
    path: '/place',
    name: 'DeepLink',
    component: () => import('../views/DeepLink.vue'),
    alias: ['/app', '/user'],
    beforeEnter: (to, _, next) => {
      localStorage.setItem('fromDeepLink', to.query.place);
      next(`/places/${to.query.place}`)
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
