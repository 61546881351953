<template>
  <div class="qr-email">
    <input
      v-model="email"
      class="qr-email__input"
      :placeholder="$t('qrOrder.emailForCheck')"
      type="email"
    />
    <div class="qr-email__rows">
      <div v-if="serviceFee" class="qr-email__row" @click="onCheckboxClick('isAgreeService')">
        <QrCheckbox
          :checked="value.isAgreeService"
          icon="checkGrey"
          @change="onCheckboxClick('isAgreeService')"
        />
        <div class="qr-email__text">
          {{ $t('qrOrder.IWantPayComission', [currencySymbol, serviceFee]) }}
        </div>
      </div>
      <div class="qr-email__row" @click="onCheckboxClick('isAgreeLicence')">
        <QrCheckbox
          :checked="value.isAgreeLicence"
          icon="checkGrey"
          @change="onCheckboxClick('isAgreeLicence')"
        />
        <i18n path="qrOrder.IagreeWith.text" tag="div" class="qr-email__text">
          <template v-slot:userAgreement>
            <a
              :href="termsOfUseURL"
              target="_blank"
              @click.stop
            >{{ $t('qrOrder.IagreeWith.userAgreement') }}</a>
          </template>
          <template v-slot:personalData>
            <a
              :href="privacyPolicyURL"
              target="_blank"
              @click.stop
            >{{ $t('qrOrder.IagreeWith.personalData') }}</a>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import {getTermsOfUseURLByLang, getPrivacyPolicyURLeByLang} from '@/utils'
import QrCheckbox from './QrCheckbox'

const LANG_BY_CERRENCY_CODE = {
  'RUB': 'ru',
  'GEL': 'ka',
}

export default {
  components: {
    QrCheckbox,
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    serviceFee: {
      type: Number,
      default: 0,
    },
    currencyCode: {
      type: String,
      default: 'USD'
    },
  },
  computed: {
    email: {
      get() {
        return this.value.email
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          email: value,
        })
      },
    },
    termsOfUseURL() {
      const lang = LANG_BY_CERRENCY_CODE[this.currencyCode] || 'en'

      return getTermsOfUseURLByLang(lang)
    },
    privacyPolicyURL() {
      const lang = LANG_BY_CERRENCY_CODE[this.currencyCode] || 'en'

      return getPrivacyPolicyURLeByLang(lang)
    },
  },
  methods: {
    onCheckboxClick(property) {
      this.$emit('input', {
        ...this.value,
        [property]: !this.value[property],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.qr-email {
  padding: 30px 16px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  background: $black;
  border-radius: 8px;

  &__input {
    width: 100%;
    height: 48px;
    background: transparent;
    border: 1px solid rgba(102, 102, 102, 1);
    outline: none;
    border-radius: 8px;
    color: $white;
    text-align: center;
    @include font(18, 21, 400);
    margin-bottom: 24px;

    &::placeholder {
      color: $secondaryText;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: $secondaryText;
    }
  }

  &__rows {
    @include flex(column, flex-start, center);
    gap: 12px;
  }

  &__row {
    width: 100%;
    @include flex(row, flex-start, center);
    text-align: left;
    gap: 10px;
  }

  &__text {
    @include font(10, 12, 400);
    flex: 1;
    color: $secondaryText;

    a,
    a:visited {
      color: unset;
    }
  }
}
</style>
